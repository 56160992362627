import React from 'react';
import styles from '../product/product.module.scss'

function Call(props) {
    return (
        <div className={styles.bg}>
            <div className={`container-md ${styles.call}`}>
                <div className={styles.call_text}>ПОЗВОНИТЕ НАМ СЕЙЧАС, ЧТОБЫ УЗНАТЬ ПОДРОБНОСТИ ИЛИ ЗАКАЗАТЬ МЕБЕЛЬ
                </div>
                <a href="tel:+375 (29) 176-85-04" className={styles.call_btn}>Позвонить</a>
            </div>
        </div>
    );
}

export default Call;