import React, {useEffect} from 'react';
import Info from "./Info/Info";
import EmailForm from "./email_form/email_form";
import Pictures from "./pictures/pictures";


function Contacts(props) {
    useEffect(() => {
        window.scrollTo(0, 0)
    });
    return (
        <div>
            <Info/>
            <EmailForm/>
            <Pictures/>
        </div>
    );
}


export default (Contacts);