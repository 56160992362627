import React from 'react';

import styles from './story.module.scss'

function Story(props) {
    return (
        <div>
            <div className={`container-md ${styles.story_block}`}>
                <div className={styles.title}>НАША ИСТОРИЯ</div>
                <div className={styles.text}>
                    ООО "Ловинтерьер" создано в июле 2019 года с целью изготовления мебели из натурального дерева и
                    светильников из рогов диких животных. <br/>
                    Мебель и светильники изготавливаются специалистами, обладающими навыками ручной резьбы по дереву и
                    кости. Для работников постоянно проводится обучение с целью повышения квалификации мастерства,
                    стажировки в других странах.
                    Наша компания молодая и амбициозная, мы открыты к совместному сотрудничеству с другими фирмами в
                    производстве мебели и реализации изделий как на территории Республики Беларусь, так и за ее
                    пределами.
                </div>
            </div>
            <div className={styles.team_img}>

            </div>
        </div>
    );
}


export default (Story);