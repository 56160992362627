import React, {useEffect} from 'react';

import Slider from "./slider/slider";
import Catalog from "./catalog/catalog";
import AboutWork from "./about_work/about_work";
import Collage from "./collage/collage";
import Tradition from "./tradition/tradition/tradition";
import Call from "../shared/call";


function MainPage(props) {
    useEffect(() => {
        window.scrollTo(0, 0)
    });
    return (
        <div>
            <Slider/>
            <Catalog/>
            <Call/>
            <AboutWork/>
            <Tradition/>
            <Collage/>
        </div>
    );
}


export default (MainPage);

