import React from 'react';
import Header from "../shared/header/header";
import MainPage from "./main_page";
import Footer from "../shared/footer/footer";

function MainPageRouter(props) {
    return (
        <div>
            <Header/>
            <MainPage/>
            <Footer/>
        </div>
    );
}


export default (MainPageRouter);