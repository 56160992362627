import React from 'react';

import styles from './about_work.module.scss'

function AboutWork(props) {
    return (
        <div className={styles.about_work}>
            <div>
                <div className={styles.sub_text}>МЫ ВДОХНОВЛЯЕМСЯ И ГОРДИМСЯ ТЕМ, ЧТО</div>
                <div className={styles.title_text}>ДЕЛАЕМ ЭКСКЛЮЗИВНУЮ МЕБЕЛЬ С&nbsp;ХАРАКТЕРОМ</div>
            </div>
        </div>
    );
}


export default (AboutWork);