import React from 'react';

import Header from "../shared/header/header";

import Footer from "../shared/footer/footer";
import About from "./about";

function AboutRouter(props) {
    return (
        <div>
            <Header/>
            <About/>
            <Footer/>
        </div>
    );
}


export default (AboutRouter);