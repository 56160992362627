import React from 'react';

import styles from './product.module.scss'
import {NavLink} from "react-router-dom";

function Categories(props) {
    return (
        <div className={styles.category}>
            <NavLink to={`byCategory/${props.link}`}>
                <div className={`${styles.img}`} style={{backgroundImage:`url('${props.img}')`, backgroundPositionX:'50%', backgroundPositionY:'50%', backgroundSize:'cover'}}> </div>
                <div className={styles.description}>
                    {props.title}
                </div>
            </NavLink>
        </div>
    );
}


export default (Categories);