import React from "react";

import styles from "../../product.module.scss";
import AwesomeSlider from "react-awesome-slider";
import table from "../ProductPage.module.scss";


function Info(props) {

    return (
        <div>
            <div className={styles.chair}>
                <div className={styles.slider}>
                    <AwesomeSlider style={{height: '70vh', minHeight: 500}}>
                            <div className={`${styles.img}`} style={{backgroundImage:`url('${props.product.images}')`, backgroundPositionX:'50%', backgroundPositionY:'50%', backgroundSize:'cover'}}> </div>
                            <div className={`${styles.img}`} style={{backgroundImage:`url('${props.product.images_2}')`, backgroundPositionX:'50%', backgroundPositionY:'50%', backgroundSize:'cover'}}> </div>
                            <div className={`${styles.img}`} style={{backgroundImage:`url('${props.product.images_3}')`, backgroundPositionX:'50%', backgroundPositionY:'50%', backgroundSize:'cover'}}> </div>
                            <div className={`${styles.img}`} style={{backgroundImage:`url('${props.product.images_4}')`, backgroundPositionX:'50%', backgroundPositionY:'50%', backgroundSize:'cover'}}> </div>
                    </AwesomeSlider>
                </div>

                <div className={styles.info}>
                    <div className={styles.title}>{props.product.name}</div>
                    <div className={`${table.text} ${styles.text}`}>
                        <div className={styles.icons_block}>
                            <div className={`${styles.desc_text} ${styles.desc_icon_1}`}>
                                {props.product.desc_1}
                            </div>
                            <div className={`${styles.desc_text} ${styles.desc_icon_2}`}>
                                {props.product.desc_2}
                            </div>
                        </div>
                        <div className={styles.icons_block}>
                            <div className={`${styles.desc_text} ${styles.desc_icon_5}`}>
                                {props.product.desc_3}
                            </div>
                            <div className={`${styles.desc_text} ${styles.desc_icon_6}`}>
                                {props.product.desc_4}
                            </div>
                        </div>
                    </div>

                    <div className={styles.price}>
                        {props.product.description}
                    </div>

                    <div className={styles.price_total}>
                        {props.product.price} р.
                    </div>

                    <div className={`${styles.btn} ${table.btn}`}>
                        <a className={styles.link} href='#order'>Заказать</a>
                    </div>
                </div>
            </div>
        </div>
    )
        ;
}


export default (Info);



