import React from 'react';

import styles from './Info.module.scss'
import Iframe from "react-iframe";

function Info(props) {
    return (
        <div className={styles.info_block}>
            <div className={styles.info}>

                <div className={styles.title}>Контактная информация</div>
                <div className={styles.desc}>
                    ООО "ЛовИнтерьер" <br/>
                    +375 (29) 176-85-04 <br/>
                    Беларусь, г. Новополоцк, ул. Ктаторова, 28/2.<br/>
                </div>


                <div className={styles.title}>Доставка</div>
                <div className={styles.desc}>
                    Доставляем по всей Беларуси. <br/>Доставка в другие страны обсуждается лично.
                </div>

            </div>
            <div className={styles.map}>
                <Iframe
                    frameBorder="0"
                    scrolling="no"
                    marginHeight="0"
                    width="100%"
                    url={`https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2257.8647487139356!2d28.62761951592211!3d55.5347250804956!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x46c485307e064075%3A0xc3e498916b61aaf!2z0YPQuy4g0JrRgtCw0YLQvtGA0L7QstCwIDI4LCDQndC-0LLQvtC_0L7Qu9C-0YbQug!5e0!3m2!1sru!2sby!4v1622719586930!5m2!1sru!2sby`}
                />
            </div>
        </div>
    );
}


export default (Info);