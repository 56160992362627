import React from 'react';

import styles from './logo.module.scss'
import img from './logo.png'
import {NavLink} from "react-router-dom";

function Logo(props) {
    return (
        <div className={styles.logo}>
            <NavLink to={'/'}><img src={img} alt=""/></NavLink>
        </div>
    );
}


export default (Logo);