import React, {useState} from 'react';
import {send} from 'emailjs-com';
import styles from './email_form.module.scss'
import spinner from './spinner.svg'

function EmailForm(props) {
    const initial_state = {
        name: '',
        email: '',
        comment: '',
        phone: '',
    }
    const [sendData, setSendData] = useState(initial_state);
    const [showMessage, setShowMessage] = useState(false);
    const [showLoading, setShowLoading] = useState(false);
    const [errors, setErrors] = useState('');

    const onChange = (e) => {
        setSendData({...sendData, [e.target.name]: e.target.value});
    }

    const onSubmit = async (e) => {
        let current_errors = ''
        e.preventDefault();
        if (!sendData.email.includes('@')) {
            current_errors += 'Недопустимый Email \r\n'
        }
        if (!sendData.phone.includes('+375')) {
            current_errors += 'Недопустимый номер телефона \r\n'
        }
        if (current_errors === '') {
            setShowLoading(true);
            await send(
                'service_i9hqpzq',
                'template_yz8ogv2',
                sendData,
                'user_Zc5zl5KzWeJIzdHQLyuR5',
            )
                .then((response) => {
                    setSendData(initial_state);

                    setShowMessage(true);
                    setTimeout(() => {
                        setShowMessage(false);
                    }, 2500);
                    current_errors = '';
                })
                .catch((err) => {
                    current_errors += err;
                });
        }
        setShowLoading(false);
        setErrors(current_errors);
    };

    return (
        <div>
            <div
                className={showMessage ? `${styles.messages} ${styles.messages_success}` : `${styles.messages} ${styles.messages_success_hide}`}>
                <p>Ваше сообщение было успешно отправленно!</p>
            </div>

            <div
                className={showLoading ? `${styles.loading} ${styles.loading_show}` : `${styles.loading} ${styles.loading_hide}`}>
                <img src={spinner} alt=""/>
            </div>


            <form className={`container-md ${styles.email_form}`} onSubmit={onSubmit}>
                <div className={styles.input_field}>
                    <div className={styles.input_title}>Имя</div>
                    <div className={styles.input_name}>
                        <input name='name' placeholder={'Ваше имя'} onChange={e => onChange(e)} value={sendData.name}
                               type="text" required/>
                    </div>


                </div>
                <div className={styles.input_field}>
                    <div className={styles.input_title}>Email</div>
                    <div className={styles.input_email}>
                        <input placeholder={'Ваш Email'} name='email' onChange={e => onChange(e)} value={sendData.email}
                               type="text" required/>
                    </div>


                </div>
                <div className={styles.input_field}>
                    <div className={styles.input_title}>Ваш номер телефона:</div>
                    <div className={styles.input_phone}>
                        <input placeholder={'+375 '} name='phone' onChange={e => onChange(e)} value={sendData.phone}
                               type="text" required/>
                    </div>


                </div>

                <div className={`${styles.input_textarea} ${styles.input_field}`}>
                    <div className={styles.input_title}>Комментарий</div>
                    <textarea name='comment' value={sendData.comment} onChange={e => onChange(e)}/>
                </div>

                <div className={styles.errors}>
                    {errors}
                </div>

                <button className={styles.btn} type={"submit"}>ОТПРАВИТЬ</button>
            </form>

        </div>
    );
}


export default (EmailForm);