import React from 'react';

import Header from "../shared/header/header";
import Footer from "../shared/footer/footer";
import Contacts from "./contacts";

function ContactsRouter(props) {
    return (
        <div>
            <Header/>
            <Contacts/>
            <Footer/>
        </div>
    );
}


export default (ContactsRouter);