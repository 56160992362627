import React, {useEffect} from 'react';
import Preview from "./preview/preview";
import Story from "./story/story";

function About(props) {
    useEffect(() => {
        window.scrollTo(0, 0)
    });
    return (
        <div>
            <Preview/>
            <Story/>
        </div>
    );
}


export default (About);