import React, {useState} from 'react';

import {NavLink} from "react-router-dom";
import styles from './header.module.scss'
import Logo from "../logo/logo";
import Phones from "../phones/phones";
import Burger from 'react-hamburger-menu';
import './transition.scss'

function Header(props) {
    const [open, setOpen] = useState(false);
    const [inProp, setInProp] = useState(true);

    const HandleClick = () => {
        setTimeout(() => {
            setInProp(!inProp)
        }, 800)
        setOpen(!open)
    }

    const clickHandler = async (e) => {


    }

    return (
        <div className={`container-md ${styles.header}`}>
            <div className={styles.left_side}>
                <Logo/>
                <Burger
                    className={styles.btn}
                    isOpen={open}
                    menuClicked={HandleClick}
                    width={28}
                    height={28}
                    strokeWidth={2}
                    rotate={0}
                    color='black'
                    borderRadius={0}
                    animationDuration={0.5}
                />
                <Phones/>

            </div>


            <div className={`${styles.links}`}>
                <NavLink onClick={clickHandler} activeClassName={styles.activeLink} exact to={`/`}>ГЛАВНАЯ</NavLink>
                <NavLink activeClassName={styles.activeLink} exact to={`/catalog`}>КАТАЛОГ</NavLink>
                <NavLink activeClassName={styles.activeLink} to={`/about`}>О&#160;НАС</NavLink>
                <NavLink activeClassName={styles.activeLink} to={`/contacts`}>КОНТАКТЫ</NavLink>
            </div>
            {
                open
                    ?
                    <div className={`${styles.visible} ${styles.links}`}>
                        <NavLink onClick={clickHandler} activeClassName={styles.activeLink} exact
                                 to={`/`}>ГЛАВНАЯ</NavLink>
                        <NavLink activeClassName={styles.activeLink} exact to={`/catalog`}>КАТАЛОГ</NavLink>
                        <NavLink activeClassName={styles.activeLink} to={`/about`}>О&#160;НАС</NavLink>
                        <NavLink activeClassName={styles.activeLink} to={`/contacts`}>КОНТАКТЫ</NavLink>
                        <a className={styles.phone_number} href="tel:+375 (29) 176-85-04"> +375 (29) 176-85-04</a>
                        <div className={styles.icons}>
                            <a href={'/'} className={styles.img_1}> </a>
                            <a href={'viber://chat?number=%2B375297102519'} className={styles.img_2}> </a>
                        </div>

                    </div>
                    : <div></div>

            }


        </div>
    );
}


export default (Header);