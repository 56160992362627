import React from 'react';

import styles from './phones.module.scss'

function Phones(props) {
    return (
        <div className={styles.phones_block}>
            <div className={styles.phones}>
                <a href="tel:+375 (29) 176-85-04"> +375 (29) 176-85-04 </a>

            </div>
            <div className={styles.icons}>
                <a href={'/'} className={styles.img_1}> </a>
                <a href={'viber://chat?number=%2B375297102519'} className={styles.img_2}> </a>
            </div>
        </div>
    );
}


export default (Phones);