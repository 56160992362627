import React from 'react';

import styles from './tradition.module.scss'

function Tradition(props) {
    return (
        <div className={`container-md ${styles.tradition}`}>
            <div className={styles.title}>КАЧЕСТВО И ТРАДИЦИИ</div>
            {/*<img className={styles.img_} src={img} alt=""/>*/}
            <div className={styles.sub_title}>Наша мебель долговечна и экологична.</div>

            <div className={styles.desc}>Вся мебель изготавливается из натуральных материалов при помощи ручного труда.
                При создании мебели мы учитываем не только общий внешний вид изделия, но и тщательно прорабатываем все
                детали. Наш ассортимент постоянно расширяется, мы открыты к экспериментам в дизайне мебели.
            </div>
        </div>
    );
}


export default (Tradition);