import React from 'react';

import styles from './pictures.module.scss'

function Pictures(props) {
    return (
        <div className={styles.pictures}>
            <div className={`${styles.pict_3} ${styles.picture}`}>
                <div className={styles.pict_title}>НАДЁЖНО</div>
            </div>
            <div className={`${styles.pict_1} ${styles.picture}`}>
                <div className={styles.pict_title}>КАЧЕСТВЕННО</div>
            </div>
            <div className={`${styles.pict_2} ${styles.picture}`}>
                <div className={styles.pict_title}>ЭКСКЛЮЗИВНО</div>
            </div>
        </div>
    );
}


export default (Pictures);