import React from 'react';

import styles from './collage.module.scss'

function Collage(props) {
    return (
        <div className={styles.collage}>
            <div className={`${styles.card} ${styles.first_card}`}></div>
            <div className={`${styles.card} ${styles.second_card}`}>
                <div className={styles.text}>
                    Каждое наше изделие создается людьми, которые с большим удовольствием занимаются своей работой. Наша
                    продукция высокого класса благодаря изысканности своего дизайна и проработке деталей, что редкость в
                    наше время. С годами такая мебель не теряет в цене, а обрастает историей.
                </div>
            </div>
            <div className={`${styles.card} ${styles.third_card}`}>
                <div className={styles.card_img_1}></div>
                <div className={styles.card_img_2}></div>
            </div>
            <div className={`${styles.card} ${styles.fours_card}`}></div>
        </div>
    );
}


export default (Collage);