import React from 'react';

import styles from './preview.module.scss'

function Preview(props) {
    return (
        <div className={styles.preview}>
            <div>
                <div className={styles.title}>О НАС</div>
                <div className={styles.text}>Мы делаем уникальную мебель в Беларуси</div>
            </div>
        </div>
    );
}


export default (Preview);