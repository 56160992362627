import React, {useEffect, useState} from 'react';
import styles from '../product.module.scss'
import EmailForm from "../../contacts/email_form/email_form";
import Call from "../../shared/call";
import Info from "./Info/info";
import URL from "../../../vars";


function ProductPage(props) {

    const url = window.location.href.split('/')
    const id = url[url.length - 1]
    const [product, setProduct] = useState([])

    const fetchProductID = () => {
        fetch(URL + 'products/' + id)
            .then(response => {
                return response.json()
            })
            .then(data => {
                setProduct(data)
            })
    }

    useEffect(() => {
        fetchProductID()
        console.log(product)
    }, [])

    return (
        <div >
            {product ? <Info product={product}/>:''}
            <Call/>
            <div id='order' className={styles.email}>
                <EmailForm/>
            </div>
        </div>
    );
}


export default (ProductPage);