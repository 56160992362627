import React from 'react';
import {Route, Switch} from 'react-router-dom'
import MainPageRouter from "./main_page/main_page_router";
import AboutRouter from "./about/about_router";
import ContactsRouter from "./contacts/contacts_router";
import Catalog_router from "./catalog/catalog_router";
import ByCategory_router from "./by_category/byCategory_router";
import ProductPageRouter from "./product/main_product/ProductPage_router";



function Routes(props) {
    return (
        <div>
            <Switch>
                <Route exact path="/"><MainPageRouter/></Route>
                {/* eslint-disable-next-line react/jsx-pascal-case */}
                <Route exact path="/catalog"><Catalog_router/></Route>
                <Route exact path="/byCategory/:slug"><ByCategory_router/></Route>
                <Route exact path="/catalog/:slug"><ProductPageRouter/></Route>
                <Route exact path="/about"><AboutRouter/></Route>
                <Route exact path="/contacts"><ContactsRouter/></Route>

            </Switch>
        </div>
    );
}

export default Routes;