import React, {useEffect, useState} from 'react';
import styles from './catalog.module.scss'

import Categories from "./categories/categories";
import URL from "../../../vars";

function Catalog(props) {
    const [categories, setCategories] = useState([])
    const fetchCategories = () => {
        fetch(URL + 'categories/')
            .then(response => {
                return response.json()
            })
            .then(data => {
                setCategories(data)
            })
    }
    useEffect(() => {
        fetchCategories()
        window.scrollTo(0, 0)
    }, [])

    return (
        <div className={`container-md ${styles.catalog_block}`}>
            <div className={styles.title}>МЫ ИЗГОТАВЛИВАЕМ</div>
            <div className={`${styles.catalog}`}>

                {
                    categories.map(category => (
                        <Categories title={category.name} img={category.image} link={category.id}/>
                    ))}

            </div>
        </div>
    );
}


export default (Catalog);