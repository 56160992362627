import React, {useEffect, useState} from 'react';
import styles from "../main_page/catalog/catalog.module.scss";
import ProductItem from "./productItem/productItem";
import URL from "../../vars";
import Categories from "../main_page/catalog/categories/categories";


function ByCategory(props) {
    const url = window.location.href.split('/')
    const id = url[url.length - 1]

    const [products, setProducts] = useState([])
    const fetchProducts = () => {
        fetch(URL + 'products/byCategory/' + id)
            .then(response => {
                return response.json()
            })
            .then(data => {
                setProducts(data)
            })
    }
    const [CatName, setCatName] = useState([])
    const fetchCatName = () => {
        fetch(URL + 'categories/' + id)
            .then(response => {
                return response.json()
            })
            .then(data => {
                setCatName(data.name)
            })
    }

    useEffect(() => {
        window.scrollTo(0, 0)
        fetchProducts()
        fetchCatName()

    }, [])


    return (
        <div className={`container-md ${styles.catalog_block}`}>
            <div className={styles.title}>{CatName}</div>
            <div className={`${styles.catalog}`}>

                {products ?
                    products.map(product => (
                        <ProductItem title={product.name} img={product.images} link={product.id}/>
                    )) : ''
                }

            </div>
        </div>
    );
}

export default ByCategory;