import React from 'react';

import styles from './footer.module.scss';
import inst_img from './inst.svg';
import vk_img from './vk.svg'
import class_img from './class.svg'
import {NavLink} from "react-router-dom";

function Footer(props) {
    const HandleClick = (e) => {
        e.preventDefault();
        window.scrollTo(0, 0);
    }

    return (
        <div className={styles.bg}>
            <div className={`${styles.footer} container-md`}>
                <div className={styles.navs}>
                    <div className={styles.footer_title}>Меню</div>
                    {
                        window.location.pathname === '/'
                            ? <NavLink onClick={e => HandleClick(e)} to={'/'}>Главная</NavLink>
                            : <NavLink to={'/'}>Главная</NavLink>

                    }
                    {
                        window.location.pathname === '/catalog'
                            ? <NavLink onClick={e => HandleClick(e)} to={'/catalog'}>Каталог</NavLink>
                            : <NavLink to={'/catalog'}>Каталог</NavLink>

                    }
                    <NavLink to={'/about'}>О нас</NavLink>
                    <NavLink to={'/contacts'}>Контакты</NavLink>
                </div>

                <div className={styles.social_nets}>
                    <div className={styles.footer_title}>Мы в соц сетях</div>
                    <a target="_blank" href="https://instagram.com/lovinterier_by" rel="noreferrer"><img src={inst_img} alt=""/></a>
                    <a target="_blank" href="https://vk.com/lovinterier_by" rel="noreferrer"><img src={vk_img} alt=""/></a>
                    <a target="_blank" href="https://ok.ru/profile/598427672351" rel="noreferrer"><img src={class_img} alt=""/></a>

                </div>

                <div className={styles.after_company}>@ 2023 LovInterier</div>
            </div>
        </div>
    );
}


export default (Footer);