import React from 'react';

import styles from './slider.module.scss';
import AwesomeSlider from 'react-awesome-slider/';
import './arrow.scss'
import 'react-awesome-slider/dist/styles.css';

function Slider(props) {
    return (
        <div className={styles.slider}>
            <AwesomeSlider>
                <div className={`${styles.img} ${styles.eight_img}`}> </div>
                <div className={`${styles.img} ${styles.first_img}`}> </div>
                <div className={`${styles.img} ${styles.second_img}`}> </div>
                <div className={`${styles.img} ${styles.third_img}`}> </div>
                <div className={`${styles.img} ${styles.fours_img}`}> </div>
                <div className={`${styles.img} ${styles.fives_img}`}> </div>
                <div className={`${styles.img} ${styles.six_img}`}> </div>
                <div className={`${styles.img} ${styles.seven_img}`}> </div>
                <div className={`${styles.img} ${styles.nine_img}`}> </div>
                <div className={`${styles.img} ${styles.ten_img}`}> </div>
            </AwesomeSlider>
        </div>
    );
}


export default (Slider);