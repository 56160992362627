import React, {useEffect} from 'react';
import Header from "../shared/header/header";
import Call from "../shared/call";
import Footer from "../shared/footer/footer";
import ByCategory from "./byCategory";

function ByCategory_router(props) {
    useEffect(() => {
        window.scrollTo(0, 0)
    });
    return (
        <div>
            <Header/>
            <ByCategory/>
            <Call/>
            <Footer/>
        </div>
    );
}

export default ByCategory_router;