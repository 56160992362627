import React, {useEffect} from 'react';
import Header from "../shared/header/header";
import Call from "../shared/call";
import Footer from "../shared/footer/footer";
import Catalog from "../main_page/catalog/catalog";

function Catalog_router(props) {
    useEffect(() => {
        window.scrollTo(0, 0)
    });
    return (
        <div>
            <Header/>
            <Catalog/>
            <Call/>
            <Footer/>
        </div>
    );
}

export default Catalog_router;