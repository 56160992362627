import React, {useEffect, useState} from 'react';

import Footer from "../../shared/footer/footer";
import Header from "../../shared/header/header";
import ProductPage from "./ProductPage";
import URL from "../../../vars";

function ProductPageRouter(props) {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <div>
            <Header/>
            <ProductPage/>
            <Footer/>
        </div>
    );
}


export default (ProductPageRouter);